<template>
  <v-card 
    outlined
    class="mb-3 mt-1"
    >
    <v-snackbar
      v-model="error"
      color="error"
      >
      Código de descuento incorrecto.
    </v-snackbar>

    <v-snackbar
      v-model="success"
      color="success"
      >
      Código de descuento aplicado con éxito.
    </v-snackbar>
    <v-expansion-panels 
      accordion 
      multiple
      :value="panels"
      >
      <v-expansion-panel 
        v-for="child, i in order.children"
        :key="child.id"
        >
        <v-expansion-panel-header class="font-weight-bold pb-2 primary--text">
          <div>
            <v-icon 
              class="mt-n1"
              color="primary"
              >
              mdi-storefront
            </v-icon>
            <span>
              {{ child.store.name }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="max-width: 880px;">
          <template
            v-for="item in child.lineItems"
            >
            <product
              :key="item.id"
              @reload="$emit('reload')"
              :item="item" 
              :orderToken="order.orderToken"
              :childToken="child.orderToken"
              />
          </template>

          <div
            class="d-flex flex-row mt-3" 
            >
            <v-text-field
              v-model="coupons[i]"
              outlined
              dense
              class="rounded-0 mr-2"
              label="Código de descuento"
              hide-details
              ></v-text-field>
            <v-btn
              outlined
              tile
              color="primary"
              style="height: 40px"
              :disabled="!coupons[i]"
              @click="applyCoupon(child, coupons[i])"
              >
              Aplicar
            </v-btn>
          </div>
          <!-- <v-divider class="mt-2"> </v-divider> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions 
      class="px-4"
      >
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          Total
        </v-col>
        <v-col cols="9">
          <v-card-subtitle class="font-weight-bold text-right">{{ order.displayLineItemsAmount }}</v-card-subtitle>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EditOrder, ApplyCoupon } from '@/graphql/mutations/order'

const product = () => import('@/components/cart/products/Detail')

export default{
  name: 'cartComponent',

  data: () => ({
    coupons: [],
    success: false,
    error: false,
  }),

  components:{
    product
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  created () {
    this.coupons = this.order.children.map( child => {
      return child.coupon
    })
  },

  computed: {
    panels () {
      return Array.from(Array(this.order.children.length).keys());
    }
  },

  methods:{
    applyCoupon (child, coupon) {
      this.$apollo.mutate({
        mutation: ApplyCoupon,
        variables: {
          input: {
            orderToken: child.orderToken,
            coupon: coupon
          }
        }
      }).then ( res => {
        this.error = !res.data.applyCoupon.success
        this.success = res.data.applyCoupon.success
      })
    },

    next () {
      this.$apollo.mutate({
        mutation: EditOrder,
        variables: {
          input: {
            orderToken: this.order.orderToken,
            attributes: {
              state: 'address',
            }
          }
        }
      }).then( res => {
        if (res.data.editOrder)
        this.$emit('next')
      })
    }
  },
}
</script>
